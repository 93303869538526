<template>
  <div class="card">
    <h1 class="text-xl font-semibold">integrations & External Apps</h1>
    <p class="text-gray-500">Connect your app to external apps and services</p>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
      <div class="bg-white border border-gray-300 shadow rounded-lg">
        <div class="p-6 pb-0">
          <img src="/WooCommerce-Icon.svg" alt="WooCommerce" class="h-16 w-16 mb-4" />
          <h3 class="text-xl font-semibold text-gray-800 mb-2">WooCommerce</h3>
          <p class="text-gray-600">
            Integrate your app with WooCommerce to seamlessly manage your
            e-commerce store.
          </p>
        </div>

        <Divider />
        <div class="px-6 pb-6">
          <Button :disabled="isWooCommerceConnected" :label="isWooCommerceConnected ? 'Connected' : 'Connect'"
            class="p-button-outlined p-button-sm" @click="openModal" />
        </div>
      </div>
      <div class="bg-white border border-gray-300 shadow rounded-lg">
        <div class="p-6 pb-0">
          <img src="/Webhook.svg" alt="WooCommerce" class="h-16 w-16 mb-4" />
          <h3 class="text-xl font-semibold text-gray-800 mb-2">Webhook</h3>
          <p class="text-gray-600">
            Use webhooks to get notified when events happen in your app.
          </p>
        </div>

        <Divider />
        <div class="px-6 pb-6">
          <Button label="Get Webhook URL" class="p-button-outlined p-button-sm" @click="openWebhookModal" />
        </div>
      </div>

      <div class="bg-white border border-gray-300 shadow rounded-lg">
        <div class="p-6 pb-0">
          <img :src="shopifyImage" alt="shopify" class="h-16 w-16 mb-4" />
          <h3 class="text-xl font-semibold text-gray-800 mb-2">Shopify</h3>
          <p class="text-gray-600">
            Integrate your app with Shopify to seamlessly manage your
            e-commerce store.
          </p>
        </div>

        <Divider />
        <div class="px-6 pb-6">
          <Button label="connect" class="p-button-outlined p-button-sm" @click="$vm2.open('ShopifyDocumentation')" />
        </div>
      </div>
    </div>
    <!-- Add more app cards here -->
    <Sidebar :visible.sync="visible" position="right" class="p-sidebar-md">
      <h2 class="font-semibold text-xl">Create a connection to WooCommerce</h2>
      <div class="bg-blue-100 border border-solid border-blue-500 p-2 mt-6">
        <ol class="text-sm pl-6 list-outside">
          <li class="mb-4">
            <p class="my-6">
              Find your API key at <strong>WooCommerce</strong> &gt;
              <strong>Settings</strong> &gt; <strong>Advanced</strong> &gt;
              <strong>REST API</strong>.
              <a href="https://woocommerce.com/document/woocommerce-rest-api/#section-2" target="_blank"
                class="text-blue-600 hover:underline">See the WooCommerce docs for more info</a>.
              <strong>Enter the <em>Key</em> and <em>Secret</em> below</strong>.
            </p>
          </li>
          <li>
            <p class="my-6">
              Enter the domain of your store as the
              <strong>URL</strong> below. For example, if you run a store at
              <code
                class="bg-red-100 text-red-800 text-xs p-1 rounded-md">https://mystore.com/wp-json/wc/v2/orders</code>,
              enter
              <code class="bg-red-100 text-red-800 text-xs p-1 rounded-md">https://mystore.com</code>
              below.
            </p>
          </li>
        </ol>
      </div>

      <form class="mt-4 space-y-6">
        <div>
          <label class="mb-4 font-semibold text-sm">Seller</label>
          <Dropdown :options="sellers" optionLabel="fullName" optionValue="_id" v-model="woocommerce.sellerId"
            placeholder="Select default seller" class="w-full" />
        </div>
        <div>
          <label class="mb-4 font-semibold text-sm">Key</label>
          <InputText type="text" class="p-inputtext-sm w-full" v-model="woocommerce.key" :required="true" />
        </div>
        <div>
          <label class="mb-4 font-semibold text-sm">Secret</label>
          <InputText type="text" class="p-inputtext-sm w-full" v-model="woocommerce.secret" :required="true" />
        </div>
        <div>
          <label class="mb-4 font-semibold text-sm">URL</label>
          <InputText type="text" class="p-inputtext-sm w-full" v-model="woocommerce.url" :required="true" />
        </div>
        <div class="flex justify-between">
          <Button :disabled="loading" label="Cancel" icon="pi pi-times" @click="closeModal"
            class="p-button-text p-button-sm" />
          <Button :disabled="loading" label="Save" icon="pi pi-check" @click="handleSubmitWC" autofocus
            class="p-button-sm" type="submit" />
        </div>
      </form>
    </Sidebar>
    <Dialog header="Your Webhook URL" :visible.sync="isOpenWebhookModal"
      :containerStyle="{ width: '35vw', overflow: 'hidden' }" :modal="true">
      <div class="bg-blue-100 border border-solid border-blue-500 p-2">
        <!-- TODO: Webhook url e.g: https://localhost:3000/api/orders/webhook?action=order.created&seller={sellerId} -->
        http://localhost:3000/api/webhooks/woocommerce
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeWebhookModal" class="p-button-text p-button-sm" />
        <Button label="Yes" icon="pi pi-check" @click="closeWebhookModal" autofocus class="p-button-sm" />
      </template>
    </Dialog>



    <vue-modal-2 name="ShopifyDocumentation" :headerOptions="{ title: $t('Integration Documentation') }"
      :noFooter="true" modalSize="xl" @on-close="CloseModal('ShopifyDocumentation')">
      <div class="relative flex flex-col w-full h-fit  px-6 pb-6">
        <loading :active.sync="generateLoading" :is-full-page="fullPage"></loading>

        <div class=" mt-2">
          <p class="marker"><span class="circle-range"></span><strong>Integration SHOPIFY with webhook</strong></p>

          <ol class="steps bx--list--ordered--temporary list--ordered--indented">
            <li class="step stepexpand bx--list__item">
              <span class="cmd">Navigate to <span class="ph menucascade"><span class="ph uicontrol">Settings</span><abbr
                    title="and then"> &gt; </abbr><span class="ph uicontrol">Notification</span></span>. (In your
                account in
                shopify)</span>
            </li>
            <li class="step stepexpand bx--list__item"><span class="cmd">Click <span class="ph uicontrol">Create
                  webhook</span>.</span></li>
            <li class="step stepexpand bx--list__item"><span class="cmd">Specify the Event. <span
                  class="ph uicontrol">(Choose Order Creation)</span>.</span></li>
            <li class="step stepexpand bx--list__item">
              <span class="cmd">Provide a URL webhook.</span>
              <div style="width:fit-content" class="flex items-center px-2 py-2 justify-start gap-4  rounded ">
                <p>Copy and paste this link </p>
                <button class=" bg-green-400  rounded text-white font-semibold text-sm px-3 py-1" @click="CopyCredentials" >copy link</button>
              </div>
              <!-- <div class="itemgroup info">Don't forget to replace <span class="ph uicontrol">{KEY_CODE}</span> and
                <span class="ph uicontrol">{SECRET_CODE}</span> with their values, you will find them in your
                <b>account settings</b>
              </div> -->
            </li>
            <li class="step stepexpand bx--list__item"><span class="cmd">Specify Webhook API version. <span
                  class="ph uicontrol">(Choose the last one)</span>.</span></li>
            <li class="step stepexpand bx--list__item"><span class="cmd">Click <span
                  class="ph uicontrol">Save</span>.</span></li>
          </ol>
          <div
            class="note border-2 bx--inline-notification--info ibmdocs-note bx--inline-notification bx--inline-notification--low-contrast margin-top-1 margin-bottom-1">
            <div class="bx--inline-notification__details"><svg focusable="false" preserveAspectRatio="xMidYMid meet"
                style="will-change: transform;" xmlns="http://www.w3.org/2000/svg" class="bx--inline-notification__icon"
                width="20" height="20" viewBox="0 0 32 32" aria-hidden="true">
                <path
                  d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,7Zm4,17.12H12V21.88h2.88V15.12H13V12.88h4.13v9H20Z">
                </path>
              </svg>
              <div class="bx--inline-notification__text-wrapper">
                <div class="bx--inline-notification__subtitle"><span
                    class="notetitle ibm-bold ibm-textcolor-blue-50 background-blue bx--inline-notification__title ibm-padding-top-1 ibm-padding-bottom-1 padding-left-1 padding-right-1">Note:</span>
                  Don't forget the synchronisation the products between SHOPIFY and afriquecod<br>
                  The product ID SHOPIFY you will find in the link to update the product page (exepmle:
                  https://admin.shopify.com/store/storeino/products/68527971688977) <br>
                  In this case our Shopify product ID is 68527971688977 <br>
                  Now we enter this product ID into our product in <b>santiago</b> exactly in a field called
                  <b>code</b> and we save the changes
                </div>
              </div>
            </div>
          </div>
          <p class="bg-red-200 my-4 rounded px-4 py-2 " style="margin-bottom: 0 !important; font-size : 13px">
            <strong>Note</strong>: Si vous utilisez le même produit
            dans plusieurs pays, vous pouvez ajouter <b>country code</b> dans la section <b>Vendeur</b> dans la partie
            <b>edit produit</b> (exemple: CI) (Dans votre compte Shopify)
          </p>
        </div>

      </div>
    </vue-modal-2>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import InlineMessage from "primevue/inlinemessage";
import Sidebar from "primevue/sidebar";
import { getAllApps, connectWooCommerce } from "../../api/integrations.api";
import { mapState } from "vuex";
import shopify from '@/assets/shopify.webp'

const DEFAULT_FROM_VALUES = {
  key: "",
  secret: "",
  url: "",
  sellerId: "",
};

const DEFAULT_APP_VALUES = {
  woocommerce: {
    isActive: false,
  },
};
export default {
  name: "Integrations",
  components: {
    Dropdown,
    Sidebar,
    Button,
    Dialog,
    Divider,
    InputText,
    InlineMessage,
  },

  data() {
    return {
      shopifyImage: shopify,
      visible: false,
      loading: false,
      apps: DEFAULT_APP_VALUES,
      woocommerce: DEFAULT_FROM_VALUES,
      isOpenWebhookModal: false,
      User : null
    };
  },

  created() {
    this.getIntegrations();
  },
  async mounted(){
    const user = await this.$server.me('users')
    if(user) this.User = user.content
  },

  computed: {
    ...mapState({
      mainSeller: (state) => state.app.defaultSeller,
      sellers: (state) => state.app.sellers,
    }),

    isWooCommerceConnected() {
      return this.apps.woocommerce.isActive;
    },
  },

  methods: {
    CopyCredentials() { 
      const connectionString = `https://api-santiago.santiago.com/api/orders/shopifyCreate?key=${this.User.api.key}&secret=${this.User.api.secret}&source=SHOPIFY`;
      const input = document.createElement('textarea');
      input.value = connectionString
      document.body.appendChild(input);
      input.select();

      document.execCommand('copy');
      document.body.removeChild(input);

      alert('Connection String Copied !', 'success')
    },

    closeWebhookModal() {
      this.isOpenWebhookModal = false;
    },
    openWebhookModal() {
      this.isOpenWebhookModal = true;
    },
    openModal() {
      this.visible = true;
      this.woocommerce = {
        ...DEFAULT_FROM_VALUES,
      };
    },

    closeModal() {
      this.visible = false;
      this.woocommerce = {
        ...DEFAULT_FROM_VALUES,
      };
    },

    async CloseModal(id) {
      this.$vm2.close(id);

    },
    async open(name) {
      this.$vm2.open(name);
    },

    getIntegrations() {
      getAllApps().then((res) => {
        this.apps = res.data.content.data;
      });
    },

    async handleSubmitWC() {
      if (
        this.woocommerce.key === "" ||
        this.woocommerce.secret === "" ||
        this.woocommerce.url === ""
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please fill all fields",
          life: 3000,
        });
        return;
      } else {
        this.loading = true;
        await connectWooCommerce(this.woocommerce)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "WooCommerce connected successfully",
              life: 3000,
            });
            this.visible = false;
            this.getIntegrations();
            this.loading = false;
            this.woocommerce = {
              ...DEFAULT_FROM_VALUES,
            };
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.bx--inline-notification {
  max-width: 100% !important;
  border-left: 3px solid #09b02a;
  background: #FF000024;
  line-height: 1.625rem;
  color: #161616;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.bx--inline-notification {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 18rem;
  height: auto;
  min-height: 3rem;
}

.bx--inline-notification--low-contrast::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  content: '';
  -webkit-filter: opacity(0.4);
  filter: opacity(0.4);
  pointer-events: none;
  border-color: #09b02a;
}

.bx--inline-notification__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 1rem;
  line-height: 1.625rem;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.bx--inline-notification__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: .875rem;
  margin-right: 1rem;
  fill: #09b02a;
}

.bx--inline-notification__text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .9375rem 0;
  line-height: 1.625rem;
}

.bx--inline-notification__subtitle {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  word-break: break-word;
}

.bx--inline-notification__title {
  display: inline !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: bold;
  margin: 0 .25rem 0 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.bx--list--ordered--temporary {
  font-size: 1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  list-style-type: decimal !important;
  line-height: 1.5rem !important;
  margin-left: 1.75rem !important;
  margin-bottom: 1.5rem !important;
  list-style: none;
}

.bx--list--ordered--temporary>.bx--list__item {
  padding-left: .25rem !important;
}

.bx--list__item {
  color: #161616;
  margin-top: .25rem;
}

.uicontrol {
  font-weight: bold;
}

.NoteP .comment {
  color: #13a171;
}

.objet-pr {
  padding-left: 10px;
  display: block;
}

.marker {
  list-style: disc outside none;
}
</style>
